<template>
  <main>
  <div class="contenu2">
  <div style="position:relative;">
     <!-- mobile -->
     <div class="logo mobile"><router-link to="/"><img  src="../assets/images/logo.png"></router-link></div>
     <img class="top-mobile mobile" src="../assets/images/top-vague4-mobile.jpg">
     <!-- commun -->
     <div class="top-contact"><img src="../assets/images/top-vide.png"></div>
  </div>
    <div class="contact">
      <p>
       SOCIETE EDITRICE :<br />
HASBRO FRANCE<br />
SAS au capitale Social de 1 700 000€<br />
Siège social :<br />
Savoie Technolac<br />
Avenue du Lac d’Annecy<br />
73370 Le Bourget Du Lac<br />
SIRET: 74622062300035<br />
APE:4649Z<br />
N° de téléphone : 04 79 96 48 48<br />
contact: contact@playdoh65.fr<br />
Déclaration CNIL sous le numéro 1324667<br />
<br />
HEBERGEMENT:<br />
AWS France<br />
SAS au capitale Social de 722 650€<br />
Siège social :<br />
67 boulevard du Général Leclerc<br />
92110 Clichy<br />
SIRET: 80986904300012<br />
APE:8211Z<br />
      </p>
    </div>
    </div>
    <img class="mobile" src="../assets/images/bottom-contact.jpg" width="100%">
  </main>
</template>
<script>
export default {
  name: 'Mentions',
}
</script>